<template>
    <v-carousel id="carousel" 
        :show-arrows="false"
        cycle
        height="100%"
        hide-delimiters
    >
        <v-carousel-item
            id="carousel-item"
            v-for="(item,i) in items"
            :key="i"
            :src="item.src"
            eager
            >   
        </v-carousel-item>
    </v-carousel>
</template>

<script>
    export default {
        name: "ImageCarousel",
        data () {
            return {
                items: [
                    {
                        src: require('@/assets/table-sit.jpg'),
                    },
                    {
                        src: require('@/assets/show-support.jpg'),
                    },
                    {
                        src: require('@/assets/sport-abuse.jpg'),
                    },
                    {
                        src: require('@/assets/visiting-cropped.jpg'),
                    },
                    {
                        src: require('@/assets/presentation-cropped.jpg'),
                    },
                ]
            }
        },
    }
</script>

<style lang="scss" scoped>
    #carousel {

        &:first-child {
            max-width: 100vw;
        }
    }

@media screen and (min-width: 1200px) {
    #carousel {

        &:first-child {
            max-width: 1350px;
        }
    }
}
</style>