<template>
<div>
    <v-list-item id="list-item"
        @click="pushRoute(item.title)"
        >
        <v-list-item-title id="drawer-title">{{item.title}}</v-list-item-title> 
        <v-btn
            v-if="linkType != 'Contact' && linkType != 'Donate' && linkType != 'Shop'"
            icon
            color="black"
            @click.stop="show = !show;"
            >
            <v-icon class="nav-icon">{{ show ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }}</v-icon>
        </v-btn>
    </v-list-item>
    <v-expand-transition>
        <div v-show="show" id="dropdown-list">
            <v-list>
                <v-list-item
                    class="menu-dropdown-items"
                    v-for="(item, index) in options"
                    :key="index"
                    @click="pushToItemLink(item)"
                    >
                    <v-list-item-title class="dropdown-title">{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </div>
    </v-expand-transition>
</div>
</template>

<script>
import router from '../router'

    export default {
        name: "NavDrawerListItem",
        props: ["item", "options", "linkType"],
        data() {
            return {
                show: false,
            }
        },
        methods: {
            pushRoute(item) {
                if (item == "Home") {
                    router.push('/');
                }
                else if (item == "Take Action") {
                    router.push('/action');
                }
                else if (item == "Explore") {
                    router.push('/explore');
                }
                else if (item == "Contact") {
                    router.push({name: 'Explore', hash: '#contact-us-container'});
                }
                else if (item == "Donate") {
                    router.push('/donate');
                }
                else if (item == "Shop") {
                    router.push('/shop');
                }
            },
            pushToItemLink(item) {
                if (this.linkType == "Home") {
                    try {
                        if (item.title == "Join the campaign list") {
                            router.push({name: 'Home', hash: '#get-campaign-members'});
                        } else if (item.title == "Our Mission"){
                            router.push({name: 'Home', hash: '#mission-statement'});
                        } else if (item.title == "Feature Campaign"){
                            router.push({name: 'Home', hash: '#feature-campaign'});
                        } else if (item.title == "Connect with us"){
                            router.push({name: 'Home', hash: '#home-connect-btn'});
                        } else {
                            router.push("/")
                        }
                    }
                    catch {
                        //do nothing
                    }
                } else if (this.linkType == "Action") {
                    try {
                        let title = item.title;
                        title = title.replace(/ /g, "-" );
                        title = title.toLowerCase();
                        router.push("/campaign/"+title);
                    }
                    catch {
                        //do nothing
                    }
                } else if (this.linkType == "Explore") {
                    try {
                        if (item.title == "Mission") {
                            router.push({name: 'Explore', hash: '#mission-statement'});
                        } else if (item.title == "Our Story") {
                            router.push({name: 'Explore', hash: '#our-story-container'});
                        } else if (item.title == "Shared principles") {
                            router.push({name: 'Explore', hash: '#shared-principles-container'});
                        } else if (item.title == "Volunteer Opportunities") {
                            router.push({name: 'Explore', hash: '#volunteer-container'});
                        } else if (item.title == "Gallery") {
                            router.push({name: 'Explore', hash: '#explore-galleries'});
                        } else if (item.title == "Living Vegan In YYC") {
                            router.push({name: 'Explore', hash: '#living-vegan-container'});
                        } else if (item.title == "Contact Us") {
                            router.push({name: 'Explore', hash: '#contact-us-container'});
                        } else {
                            router.push("/explore")
                        }
                    }
                    catch {
                        //do nothing
                    }
                } else {
                    //do nothing
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
#list-item {
    color: black;
    font-family: "Helvetica";
}
#dropdown-list {
    background-color: rgba(255, 255, 255, 0.5);
    font-family: "Helvetica";

    .menu-dropdown-items {
        color: black;
        font-family: "Helvetica";
        margin-left: 20px;

        .dropdown-title {
            border-bottom: 1px solid #0000000e; 
        }
    }
}
</style>