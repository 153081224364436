import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    campaignTitleArray: null,
    shoppingCart: [],
  },
  mutations: {
    titleArray(state, array) {
      if (state.campaignTitleArray == null) {
        state.campaignTitleArray = array;
      }
    },
    loadSavedCart(state, cart) {
      state.shoppingCart = cart;
    },
    emptyTheCart(state) {
      state.shoppingCart = [];
      //only sremove session storage data if user has accepted cookie/data storage
      let isAllowed = localStorage.getItem("casIsCookieAccept");
      if (isAllowed == "true") { 
        sessionStorage.removeItem("_casShpStore");
      }
    },
    addItemToCart(state, cartItem) {
      //console.log(JSON.parse(JSON.stringify(item)));
      //if cart is empty, just add item
      if (state.shoppingCart.length == 0) {
        cartItem.quantity = 1;
        state.shoppingCart.push(cartItem);
      }
      //if not empty, check through cart to see if item exists, then just change quantity
      else {
        let count = 0;
        let updatedItem = cartItem;

        for (let i=0;i<state.shoppingCart.length;i++) {
          if (state.shoppingCart[i].id == updatedItem.id) {
            updatedItem.quantity = state.shoppingCart[i].quantity + 1;
            
            if (state.shoppingCart[i].chosenSize != null) {
              updatedItem.chosenSize = [updatedItem.oldSize, updatedItem.chosenSize].join(", ");
            }
            count++;
          }
        }

        if (count == 1) {
          state.shoppingCart = state.shoppingCart.map(i => i.id != updatedItem.id ? i : updatedItem);
        }

        //if no quantity increase, add new item to cart
        if (count == 0) {
          cartItem.quantity = 1;
          state.shoppingCart.push(cartItem);
        }
      }
      //only set session storage data if user has accepted cookie/data storage
      let isAllowed = localStorage.getItem("casIsCookieAccept");
      if (isAllowed == "true") { 
        sessionStorage.setItem("_casShpStore", JSON.stringify(state.shoppingCart));
      }
    },
    removeCartQuantity(state, id) {
      for (let i=0;i<state.shoppingCart.length;i++) {
        if (state.shoppingCart[i].id == id) {

          if (state.shoppingCart[i].quantity > 1) {
            state.shoppingCart[i].quantity = state.shoppingCart[i].quantity - 1;
            
            if (state.shoppingCart[i].chosenSize != null) {
              let updateSizes = state.shoppingCart[i].chosenSize.split(", ");
              updateSizes.pop();
              state.shoppingCart[i].chosenSize = updateSizes.join(", ");
            }

            let newItem = state.shoppingCart[i];
            state.shoppingCart.splice([i], 1, newItem); // replace old object with new matching object for component re render.

          }
          //remove item completly if only one of the item exists
          else {
            state.shoppingCart.splice([i], 1)
          }
        }
      }

      //only set session storage data if user has accepted cookie/data storage
      let isAllowed = localStorage.getItem("casIsCookieAccept");
      if (isAllowed == "true") { 
        sessionStorage.setItem("_casShpStore", JSON.stringify(state.shoppingCart));
      }
    },
    addCartQuantity(state, id) {
      for (let i=0;i<state.shoppingCart.length;i++) {
        if (state.shoppingCart[i].id == id) {
          if (state.shoppingCart[i].quantity < 99) {
            state.shoppingCart[i].quantity += 1;

            if (state.shoppingCart[i].chosenSize != null) {
              let updateSizes = state.shoppingCart[i].chosenSize.split(", ");
              let addSize = updateSizes.slice(-1);
              updateSizes.push(addSize);
              state.shoppingCart[i].chosenSize = updateSizes.join(", ");
            }

            let newItem = state.shoppingCart[i];
            state.shoppingCart.splice([i], 1, newItem); // replace old object with new matching object for component re render.
          }
        }
      }

      //only set session storage data if user has accepted cookie/data storage
      let isAllowed = localStorage.getItem("casIsCookieAccept");
      if (isAllowed == "true") { 
        sessionStorage.setItem("_casShpStore", JSON.stringify(state.shoppingCart));
      }
    },
    removeItemFromCart(state, id) {
      for (let i=0;i<state.shoppingCart.length;i++) {
        if (state.shoppingCart[i].id == id) {
          state.shoppingCart.splice([i], 1)
        }
      }

      //only set session storage data if user has accepted cookie/data storage
      let isAllowed = localStorage.getItem("casIsCookieAccept");
      if (isAllowed == "true") { 
        sessionStorage.setItem("_casShpStore", JSON.stringify(state.shoppingCart));
      }
    },
  },
  actions: {

  },
  modules: {
  }
})
