<template>
    <div id="footer">
        <div id="socials">
            <a href="https://www.facebook.com/calgaryanimalsave" target="_blank"><img src="@/assets/facebookIcon.png" alt="Facebook"></a>
            <a href="https://www.instagram.com/calgaryanimalsave" target="_blank"><img src="@/assets/instagramIcon.png" alt="Instagram"></a>
            <a href="https://twitter.com/animalsavemvmt" target="_blank"><img src="@/assets/twitterIcon.png" alt="Twitter"></a>
        </div>
        <a id="privacy" @click="$router.push('/privacy-policy')">Privacy Policy</a>
        <p>© 2022 Calgary Animal Save</p>
        <v-btn
            v-if="ulog"
            id="logout-btn"
            color="error"
            @click="logoutSession"
            >
            Logout
        </v-btn>
    </div>
</template>

<script>
import cookies from 'vue-cookies'
import axios from 'axios'
import router from '../router'

    export default {
        name: "Footer",
        computed: {
            sessionCookie() {
                return cookies.get('_casLt') 
            },
        },
        mounted() {
            if (this.sessionCookie != undefined){
                this.ulog = true
            }
        },
        data() {
            return {
                ulog: false,
            }
        },
        methods: {
            logoutSession() {
                let token = cookies.get('_casLt');
                
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/login',
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'loginToken': token
                    }
                }).then(() => {
                    cookies.remove('_casLt');
                    router.go()
                }).catch((error) => {
                    console.log(error.response);
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    #footer {
        height: 70px;
        width: 100vw;
        background-color: #d57f1e;
        display: grid;
        grid-template-columns: 50% 50%;
        justify-items: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        overflow: hidden;

        #logout-btn {
            position: absolute;
            bottom: 0;
            right: 0;
            margin-bottom: 10px;
            margin-right: 10px;
            font-size: 0.5em;
            width: 50px;
            height: 20px;
        }

        #socials {
            grid-column: 1;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-items: center;
            align-items: center;

            a {
                width: fit-content;
            }

            img {
                width: 30px;
                margin: 5px 10px 0 10px;

                &:hover {
                    opacity: 0.8;
                }
            }
        }

        #privacy {
            font-size: 0.8em;
            color: white;
            text-decoration: underline;
            font-family: "Helvetica";

            &:hover {
                opacity: 0.8;
            }
        }

        p {
            font-size: 0.7em;
            font-family: "Helvetica";
            color: white;
            justify-self: center;
            align-self: end;
            grid-column: 1 / 3;
            position: relative;
            top: 7px;
        }
    }

@media screen and (min-width: 600px) {
    #footer {
        height: 150px;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: 100%;
        justify-items: center;

        #logout-btn {
            font-size: 1em;
            width: 100px;
            height: 40px;
        }

        #socials {
            grid-column: 1;
            grid-row: 1;

            img {
                width: 60px;
            }
        }

        #privacy {
            grid-row: 2;
            font-size: 1em;
        }

        p {
            grid-row: 3;
            font-size: 1em;
        }
    }
}

@media screen and (min-width: 1000px) {
    #footer {

        #logout-btn {
            margin-bottom: 30px;
            margin-right: 70px;
            font-size: 1.2em;
            width: 150px;
            height: 40px;
        }
    }
}
</style>