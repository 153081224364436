<template>
  <div id="home-page">
    <HeroSection />
    <div id="get-campaign-members">
      <h1 id="member-form-title">Get updates from Calgary Animal Save</h1>
      <p id="member-form-subtitle">Join our email list for all the latest campaign updates and action alerts.</p>
      <div id="camp-form-container">
        <CampaignForm />
      </div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-img
            id="site-logo"
            src="@/assets/logo.png" 
            alt="Site logo" 
            @click="$router.push('/explore')"
            v-bind="attrs"
            v-on="on"
          ></v-img>
        </template>
        <span>Visit our explore page</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-img
            id="asm-logo"
            src="@/assets/asmLogo.png" 
            alt="Animal Save Movement logo" 
            @click="goToAnimalMovementSite"
            v-bind="attrs"
            v-on="on"
          ></v-img>
        </template>
        <span>Visit thesavemovement.org</span>
      </v-tooltip>
    </div>
    <div id="mission-container">
      <MissionStatement id="mission-statement" />
    </div>
    <v-lazy
        v-model="lazyLinksDonate"
        :options="{
        threshold: 0.1
        }"
        min-height="200"
        >
      <div id="donate-home-section-test">
        <a href="/donate"><img id="donate-vector-img" src="@/assets/donate-hands.png" alt="Accepting donation vector"></a>
      </div>
    </v-lazy>
    <div id="feature-campaign">
      <FeatureCampaign />
    </div>
    <div id="vol-ops-request">
      <img id="leo-quote-animal" src="@/assets/LeoQuote.jpg" alt="Leo Tolstoy quote">
      <div id="vol-ops-text">
        <h3>Calgary Animal Save is urgently seeking:</h3>
        <ul>
          <li>A social media coordinator (estimated 5 hours a week)</li>
          <li>A vigil coordinator (estimated 2 hours a week)</li>
        </ul>
        <v-btn
          id="vol-ops-contact-details"
          color="#d57f1e"
          href="/explore#volunteer-form"
          >
          Contact us for details!
        </v-btn>
      </div>
    </div>
    <div id="home-connect-btn">
      <ConnectWithUsButton />
    </div>
  </div>
</template>

<script>
import HeroSection from '../components/HeroSection.vue'
import MissionStatement from '../components/MissionStatement.vue'
import ConnectWithUsButton from '../components/ConnectWithUsButton.vue'
import CampaignForm from '../components/CampaignForm.vue'
import FeatureCampaign from '../components/FeatureCampaign.vue'

  export default {
    name: 'Home',
    components: {
      HeroSection, 
      MissionStatement,
      ConnectWithUsButton,
      CampaignForm,
      FeatureCampaign,
    },
    data() {
      return {
        pageLoaded: false,
        lazyLinksDonate: false,
      }
    },
    methods: {
      goToAnimalMovementSite() {
        window.open("https://thesavemovement.org/", "_blank");
      },
    }
  }
</script>

<style lang="scss" scoped>
  #home-page {
    width: 100vw;
    margin-top: 70px; //account for fixed nav-bar height
    margin-bottom: 70px; //account for absolute footer height
    background: white;
    font-family: "Helvetica";
    box-shadow: 0 20px 20px  rgba(131, 131, 131, 0.568);

    #get-campaign-members {
      display: grid;
      grid-template-columns: 50% 50%;
      justify-items: center;
      align-items: center;
      text-align: center;
      
      #member-form-title {
        grid-column: 1 / 3;
        font-family: "Helvetica";
        align-self: end;
        margin: 5vh 4vw 1vh 4vw;
        font-size: 1.6em;
        color: #d57f1e;
        background:
          linear-gradient(#5565d9 0 0) 50% 100% /40% 2px;
        background-repeat:no-repeat;
        padding-bottom:6px;
      }

      #member-form-subtitle {
        grid-column: 1 / 3;
        margin: 0 5vw 3vh 5vw;
        font-family:  'Roboto', sans-serif;
      }

      #camp-form-container {
        grid-column: 1 / 3;
        margin-bottom: 5vh;
        width: 100vw;
      }
      
      #site-logo {
        grid-column: 1;
        width: 50%;
        max-width: 200px;
        cursor: pointer;
      }
      #asm-logo {
        grid-column: 2;
        width: 50%;
        max-width: 200px;
        cursor: pointer;
        box-shadow: 0 0 20px 10px #ffffff34;
      }
    }

    #mission-container {
      background-color: #d5801e17;
      box-shadow: 2px 12px 20px 2px rgba(199, 198, 198, 0.342);
      margin-top: 30px;
    }

    #donate-home-section-test {
      width: 100%;
      padding: 30px 20px;
      margin-top: 20px;

      a {
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    #feature-campaign {
      width: 100%;
      margin-bottom: 20px;
    }

    #vol-ops-request {
      display: grid;
      justify-items: center;
      background-color: #d5801e17;
      padding: 30px 0;
      margin-bottom: 20px;
      box-shadow: 2px 12px 20px 2px rgba(199, 198, 198, 0.342);

      #vol-ops-text {
        width: 100%;
        padding: 20px 20px;
        width: fit-content;
        font-size: 1.3em;
        display: grid;

        h3 {
          color: #d57f1e;
        }

        #vol-ops-contact-details {
          color: white;
          margin-top: 20px;
        }
      }

      #leo-quote-animal {
        width: 100%;
        max-width: 580px;
      }
    }

    #home-connect-btn {
      width: 100%;
      display: grid;
      justify-items: center;
    }
  }

@media screen and (min-width: 600px) {
  #home-page {
    margin-bottom: 150px; //account for absolute footer height
    display: grid;

    #get-campaign-members {
    
      #camp-form-container {
        width: 70%;
      }
    }

    #donate-home-section-test {
      display: grid;
      justify-items: center;
      a {
        width: 50%;
        overflow: hidden;
        img {
          width: 100%;
          object-fit: cover;
          transition: 7s all ease-in-out;
        }
      }

      a:hover {

        img {
          transform: scale(1.3);
        }
      }
    }

    #donate-home-section {
      grid-template-columns: 50% 50%;
      align-items: center;

      h1 {
        grid-column: 1 / 3;
        grid-row: 1;
      }

      #donate-vector-img {
        width: 60%;
        max-width: 300px;
        grid-area: 2 / 1;
      }

      a {
        grid-area: 2 / 2;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  #home-page {
    max-width: 1350px;
    min-height: calc(100vh - 230px);
    margin-top: 80px; //account for fixed nav-bar height

    #get-campaign-members {
    
      #member-form-title {
        font-size: 2.5em;
        background:
          linear-gradient(#5565d9 0 0) 50% 100% /50% 4px;
        background-repeat:no-repeat;
        margin: 5vh 4vw 2vh 4vw;
      }

      #member-form-subtitle {
        font-size: 1.2em;
        margin: 0 5vw 3vh 5vw;
      }

      #camp-form-container {
        width: 100%;
        margin-bottom: 4vh;
      }

      #site-logo {
        width: 40%;
      }

      #asm-logo {
        width: 40%;
      }
    }

    #donate-home-section {

      #donate-vector-img {
        justify-self: end;
        margin-right: 40px;
      }

      a {
        justify-self: start;
        margin-left: 40px;
      }
    }

    #vol-ops-request {
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
      align-items: center;

      #vol-ops-text {
        grid-column: 1;
        grid-row: 1;
      }

      #leo-quote-animal {
        grid-column: 2;
        grid-row: 1;
        border-radius: 5px;
      }
    }
  }
}
</style>
