<template>
    <div v-if="shoppingCart != 0">
        <v-menu
            max-height="50vh"
            v-model="menuOpen"
            :close-on-content-click="false"
            :nudge-width="200"
            
            >
            <template v-slot:activator="{ on, attrs }">
                <div
                    v-bind="attrs"
                    v-on="on"
                    >
                    <v-badge
                    :content="cartQuantity"
                    :value="cartQuantity"
                    color="green"
                    offset-y="10"
                    offset-x="8"
                    >
                    <img id="cart-icon" src="@/assets/shopping-cart.svg" alt="Shopping Cart">
                </v-badge>
                </div>
            </template>

            <v-card>
                <v-list>
                <v-list-item>
                    <v-list-item-content>
                    <v-list-item-title class="cart-font">Your Cart</v-list-item-title>
                    <v-list-item-subtitle class="cart-font">You can update your cart at checkout.</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                </v-list>

                <v-divider></v-divider>
                
                <div id="cart-item-container" v-for="item in shoppingCart" :key="item.id">
                    <CartItem :item="item" :key="cartQuantity"/> <!--cart quantity set as key so component re renders whenever computed value of cartquantity changes-->
                </div>
                <v-spacer></v-spacer>
                <div class="cart-total">
                    <span class="cart-font" >Total: ${{totalPrice}} CAD</span>
                </div>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-btn
                    id="checkout-btn"
                    @click="menuOpen = false; $router.push('/checkout')"
                >
                    Go To Checkout
                </v-btn>
                </v-card-actions>
            </v-card>
    </v-menu>
    </div>
</template>

<script>
import CartItem from "./CartItem.vue"

    export default {
        name: "ShoppingCart",
        components: {
            CartItem,
        },
        computed: {
            shoppingCart() {
                return this.$store.state.shoppingCart;
            },
            cartQuantity() {
                let cart = this.$store.state.shoppingCart;
                let cartCount = 0;
                for (let i=0;i<cart.length;i++) {
                    cartCount += cart[i].quantity;
                }
                return cartCount;
            },
            totalPrice() {
                let cart = this.$store.state.shoppingCart;
                let cartPrice = 0;
                for (let i=0;i<cart.length;i++) {
                    cartPrice += Number(cart[i].quantity * cart[i].price);
                }

                return cartPrice;
            }
        },
        beforeMount() {
            let isAccept = localStorage.getItem("casIsCookieAccept");
            if (isAccept == "true") {
                let storedCart = JSON.parse(sessionStorage.getItem("_casShpStore"));

                if (storedCart != null) {
                    this.$store.commit("loadSavedCart", storedCart);
                }
                
            }
        },
        data() {
            return {
                menuOpen: false,
            }
        }
    }
</script>

<style lang="scss" scoped>

    .cart-font {
        font-family: "Helvetica";
    }

    .cart-total {
        font-size: 20px;
        display: grid;
        justify-items: end;

        span {
            margin-right: 15px;
        }
    }

    #cart-icon {
        width: 40px;
        cursor: pointer;
        transition: 0.2s all ease-in-out;

        &:hover {
            transform: scale(1.2)
        }
    }

    #cart-item-container {
        padding: 10px 10px;
        font-family: "Helvetica";
    }

    #checkout-btn {
        min-width: 165px;
        width: 100%;
        height: 50px;
        letter-spacing: 0.5px;
        line-height: 50px;
        padding: 0 35px 0 35px;
        font-size: 15px;
        background-color: #d57f1e;
        color: whitesmoke;
        border: none;
        text-transform: uppercase;
        font-family: "Helvetica";
        font-weight: bolder;
        cursor: pointer;
        display: flex;
        justify-content: center;

        &:hover {
            background-color: #f0860e;
        }
    }
</style>