<template>
    <v-menu offset-y open-on-hover v-if="showNav" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
            <a
            id="top-btn"
            class="nav-anchors"
            @click="pushRoute(item.title)"
            v-bind="attrs"
            v-on="on"
            >
            {{item.title}}
            </a>
        </template>
        <v-list v-if="linkType != 'Contact' && linkType != 'Shop'">
            <v-list-item
                class="menu-dropdown-items"
                v-for="(item, index) in options"
                :key="index"
                @click="pushToItemLink(item)"
                >
                <v-list-item-title class="dropdown-title">{{ item.title }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import router from '../router'

    export default {
        name: "NavDesktopButton",
        props: ["showNav", "item", "options", "linkType"],
        methods: {
            pushRoute(item) {
                if (item == "Home") {
                    router.push('/');
                }
                else if (item == "Take Action") {
                    router.push('/action');
                }
                else if (item == "Explore") {
                    router.push('/explore');
                }
                else if (item == "Contact") {
                    router.push({name: 'Explore', hash: '#contact-us-container'});
                }
                else if (item == "Donate") {
                    router.push('/donate');
                }
                else if (item == "Shop") {
                    router.push('/shop');
                }
            },
            pushToItemLink(item) {
                if (this.linkType == "Home") {
                    try {
                        if (item.title == "Join the campaign list") {
                            router.push({name: 'Home', hash: '#get-campaign-members'});
                        } else if (item.title == "Our Mission"){
                            router.push({name: 'Home', hash: '#mission-statement'});
                        } else if (item.title == "Feature Campaign"){
                            router.push({name: 'Home', hash: '#feature-campaign'});
                        } else if (item.title == "Connect with us"){
                            router.push({name: 'Home', hash: '#home-connect-btn'});
                        } else {
                            router.push("/")
                        }
                    }
                    catch {
                        //do nothing
                    }
                } else if (this.linkType == "Action") {
                    try {
                        let title = item.title;
                        title = title.replace(/ /g, "-" );
                        title = title.toLowerCase();
                        router.push("/campaign/"+title);
                    }
                    catch {
                        //do nothing
                    }
                } else if (this.linkType == "Explore") {
                    try {
                        if (item.title == "Mission") {
                            router.push({name: 'Explore', hash: '#mission-statement'});
                        } else if (item.title == "Our Story"){
                            router.push({name: 'Explore', hash: '#our-story-container'});
                        } else if (item.title == "Shared principles"){
                            router.push({name: 'Explore', hash: '#shared-principles-container'});
                        } else if (item.title == "Volunteer Opportunities"){
                            router.push({name: 'Explore', hash: '#volunteer-container'});
                        } else if (item.title == "Gallery"){
                            router.push({name: 'Explore', hash: '#explore-galleries'});
                        } else if (item.title == "Living Vegan In YYC"){
                            router.push({name: 'Explore', hash: '#living-vegan-container'});
                        } else if (item.title == "Contact Us"){
                            router.push({name: 'Explore', hash: '#contact-us-container'});
                        } else {
                            router.push("/explore")
                        }
                    }
                    catch {
                        //do nothing
                    }
                } else {
                    //do nothing
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.nav-anchors {
        font-weight: bold;
        color: black;
        width: 100%;
        display: grid;
        justify-items: center;
        transition: all 0.4s ease-in-out;
        font-family: "Helvetica";

        &:hover {
            color: #5565d9;
        }
    }

    .break-line {
        width: 10%;
        height: 50%;
        background-color: black;
        opacity: 0.7;
    }

.menu-dropdown-items {
    width: 100%;
    cursor: pointer;
    font-family: "Helvetica";
    
    &:hover {
        background-color: #e89f4c54;
    }
}

@media screen and (min-width: 1400px) {
    .nav-anchors {
        font-size: 1.4em;
    }
}
</style>