<template>
    <div id="camp-form-comp" @click="hideMsg()">
        <div id="form-box" >
            <v-form ref="campForm" id="campaign-form" v-model="formValid">
                <v-text-field
                    v-model="firstName"
                    :rules="[nameRules.required, nameRules.min, nameRules.max]"
                    filled
                    color="primary"
                    background-color="#f8f8f8"
                    required
                    >
                    <template v-slot:label>
                        First name<span class="red--text"> *</span>
                    </template>
                </v-text-field>
                <v-text-field
                    v-model="lastName"
                    :rules="[nameRules.required, nameRules.min, nameRules.max]"
                    filled
                    color="primary"
                    background-color="#f8f8f8"
                    required
                    >
                    <template v-slot:label>
                        Last name<span class="red--text"> *</span>
                    </template>
                </v-text-field>
                <v-text-field
                    v-model="email"
                    :rules="[emailRules.required, emailRules.valid, emailRules.max]"
                    filled
                    color="primary"
                    background-color="#f8f8f8"
                    required
                    >
                    <template v-slot:label>
                        Email<span class="red--text"> *</span>
                    </template>
                </v-text-field>
                <v-text-field
                    v-model="city"
                    :rules="[nameRules.required, nameRules.min, nameRules.max]"
                    filled
                    color="primary"
                    background-color="#f8f8f8"
                    required
                    >
                    <template v-slot:label>
                        City/Town<span class="red--text"> *</span>
                    </template>
                </v-text-field>
                <div id="error-submit">
                    <h3>{{errorMsg}}!</h3>
                </div>
                <div id="success-submit">
                    <h3>Success! Thanks for signing up for email alerts.</h3>
                </div>
                <v-btn id="subscribe-btn"
                    :disabled="!formValid"
                    elevation="6"
                    color="primary" 
                    @click="subscribeAlerts">
                        Subscribe!
                </v-btn>
            </v-form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

    export default {
        name: "CampaignForm",
        data() {
            return {
                email: '',
                emailRules: {
                    required: v => !!v || 'E-mail is required',
                    valid: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    max: v => v.length <= 50 || 'Max 50 characters'
                },
                firstName: '',
                lastName: '',
                city: '',
                nameRules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 1 || 'Min 1 character',
                    max: v => v.length <= 50 || 'Max 50 characters',
                },
                formValid: false,
                errorMsg: '',
            }
        },
        methods: {
            hideMsg() {
                document.getElementById('error-submit').style.display = "none";
                document.getElementById('success-submit').style.display = "none";
            },
            subscribeAlerts() {
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/email_lists',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        'tableType': 'campaign',
                        'email': this.email,
                        'firstName': this.firstName,
                        'lastName': this.lastName,
                        'city': this.city
                    }
                }).then(() => {
                    document.getElementById('success-submit').style.display = "block";
                }).catch((error) => {
                    this.errorMsg = error.response.data;
                    document.getElementById('error-submit').style.display = "block";
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    #camp-form-comp {
        width: 100vw;
        
        #form-box {
            height: 50%;
            padding: 5% 5%;
            display: grid;
            background: rgb(232, 159, 76);
            background: linear-gradient(45deg, rgb(255, 187, 110) 0%, rgb(213, 128, 30) 100%);
            box-shadow: 2px 10px 20px 4px rgba(213, 128, 30, 0.267);
            border-radius: 5px;
            
            #campaign-form {
                width: 90%;
                justify-self: center;
                
                #error-submit {
                    display: none;
                    color: rgb(252, 0, 0);
                    margin-bottom: 2vh;
                    background-color: rgba(255, 255, 255, 0.473);
                    padding: 5px;
                    border-radius: 10px;
                    box-shadow: 3px 4px 7px rgba(0, 0, 0, 0.726);
                }

                #success-submit {
                    display: none;
                    color: #22bb33;
                    margin-bottom: 2vh;
                    background-color: rgba(238, 238, 238, 0.87);
                    padding: 5px;
                    border-radius: 10px;
                    box-shadow: 3px 4px 7px rgba(0, 0, 0, 0.726);
                }

                #subscribe-btn {
                    width: 90%;
                }
            }
        }
    }

@media screen and (min-width: 600px) {
    #camp-form-comp {
        width: 100%; //set width of parent container to change with of component (in Home.vue)

        #form-box {
            padding: 5% 10%;

            #campaign-form {
                
                #subscribe-btn {
                    width: 70%;
                }
            }
        }  
    }
}

@media screen and (min-width: 1000px) {
    #camp-form-comp {
        //set width of parent container to change with of component (in Home.vue)
        display: grid;
        height: 550px;
        padding: 0 20%;
        gap: 0 10px;
        position: relative;
        right: 5px;

        #form-box {
            padding: 10px 20px;
            height: 100%;
            display: grid;
            align-self: start;
            gap: 0 10px;


            #campaign-form {
                height: fit-content;
                justify-self: center;
                align-self: center;

                #subscribe-btn {
                    width: 90%;
                }
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    #camp-form-comp {
        padding: 0 25%;
    }
}
</style>