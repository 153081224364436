<template>
    <div id="nav-bar">
        <img id="logo" @click="$router.push('/')" src="@/assets/logo.png" alt="Logo">
        <h1 id="header-title">Calgary Animal Save</h1>
        <v-navigation-drawer
            id="nav-drawer"
            v-model="navDrawer"
            absolute
            bottom
            color="#fffcf2">
            <div id="drawer-header">
                <img id="drawer-logo" @click="$router.push('/')" src="@/assets/logo.png" alt="Logo">
                <h3>Calgary Animal Save</h3>
            </div>
            <hr>
            <NavDrawerListItem id="home-nav-item" :item="items[0]" :options="homeBtnItems" linkType="Home" @click.self="$router.push('/')"/>
            <hr>
            <NavDrawerListItem id="action-nav-item" :item="items[1]" :options="campaigns" linkType="Action" @click.self="$router.push('/action')"/>
            <hr>
            <NavDrawerListItem id="explore-nav-item" :item="items[2]" :options="exploreItems" linkType="Explore" @click.self="$router.push('/explore')"/>
            <hr>
            <NavDrawerListItem id="donate-nav-item" :item="items[4]" linkType="Donate"/>
            <hr>
            <NavDrawerListItem id="contact-nav-item" :item="items[5]" linkType="Shop"/>
            <hr>
            <NavDrawerListItem id="contact-nav-item" :item="items[3]" linkType="Contact"/>
        </v-navigation-drawer>
        <!--Code for desktop view-->
        <div id="desktop-btn-container">
            <NavDesktopButton :showNav="showNav" :item="items[0]" :options="homeBtnItems" linkType="Home"/>
            <div class="break-line"></div>
            <NavDesktopButton :showNav="showNav" :item="items[1]" :options="campaigns" linkType="Action"/>
            <div class="break-line"></div>
            <NavDesktopButton :showNav="showNav" :item="items[2]" :options="exploreItems" linkType="Explore"/>
            <div class="break-line"></div>
            <a class="extra-desk-btns" href="/shop">Shop</a>
            <div class="break-line"></div>
            <a class="extra-desk-btns" href="/explore#contact-us-container">Contact</a>
        </div>
        <div id="shop-cart-component">
            <ShoppingCart />
        </div>
        <v-icon id="icon" @click="openDrawer">{{hambIcon}}</v-icon>
        <v-btn
            id="donate-btn"
            color="#5565d9"
            width="100px"
            @click="$router.push('/donate')"
            >
            Donate
        </v-btn>
    </div>
</template>

<script>
import axios from 'axios'
import NavDrawerListItem from '../components/NavDrawerListItem.vue'
import NavDesktopButton from '../components/NavDesktopButton.vue'
import ShoppingCart from './ShoppingCart.vue'

    export default {
        name: "NavBar",
        props: ["showNav"],
        components: {
            NavDrawerListItem,
            NavDesktopButton,
            ShoppingCart
        },
        beforeMount() {
            this.loadCampaigns();
        },
        data() {
            return {
                hambIcon: "menu",
                navDrawer: false,
                items: [
                    { title: 'Home' },
                    { title: 'Take Action' },
                    { title: 'Explore' },
                    { title: 'Contact' },
                    { title: 'Donate' },
                    { title: 'Shop' },
                ],
                homeBtnItems: [
                    {title: "Join the campaign list"},
                    {title: "Our Mission"},
                    {title: "Feature Campaign"},
                    {title: "Connect with us"},
                ],
                campaigns: [],
                exploreItems: [
                    {title: "Mission"},
                    {title: "Our Story"},
                    {title: "Shared principles"},
                    {title: "Gallery"},
                    {title: "Volunteer Opportunities"},
                    {title: "Living Vegan In YYC"},
                    {title: "Contact Us"},
                ],
            }
        },
        methods: {
            openDrawer() {
                this.navDrawer = true;
            },
            closeDrawer() {
                this.navDrawer = false;
            },
            loadCampaigns() {
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/action',
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    let allObjects = response.data.reverse(); //reverse so nerwest campaings show first
                    let  slicedObjects = allObjects.slice(0, 10) // set amount of capaigns visible on drop down
                    this.campaigns = slicedObjects;
                }).catch((error) => {
                    console.log(error);
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Yellowtail&display=swap');

    #nav-bar {
        height: 70px;
        width: 100vw;
        z-index: 99;
        display: grid;
        background-color: #fffcf2;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.6);
        align-items: center;
        grid-template-columns: 20% 50% 5% 25%;

        #logo {
            width: 55px;
            justify-self: start;
            margin-left: 10px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                transform: scale(1.1);
            }
        }

        #header-title {
            display: none;
        }

        #shop-cart-component {
            justify-self: end;
            align-self: center;
            position: relative;
            top: 2px;
        }

        #icon {
            grid-column: 4;
            justify-self: end;
            margin-right: 20px;
            font-size: 3em;
            color: #d57f1e;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                transform: scale(1.1);
            }
        }

        #nav-drawer {
            position: fixed;

            #drawer-header {
                display: grid;
                grid-template-columns: 30% 70%;
                align-items: center;

                #drawer-logo {
                    height: 50px;
                    margin-left: 10px;
                    margin-top: 5px;
                    cursor: pointer;
                }

                h3 {
                    grid-column: 2;
                    font-family: "Helvetica";
                    justify-self: center;
                    color: black;
                }
            }

            hr {
                margin-top: 1vh;
            }
        }

        #desktop-btn-container {
            display: none;
        }

        #donate-btn {
            display: none;
        }
    }

@media screen and (min-width: 600px) {
    #nav-bar {
        #header-title {
            display: inline-block;
            grid-column: 2;
            justify-self: center;
            margin-left: 15%;
            color: #d57f1e;
            font-family: 'Yellowtail', cursive;
            font-size: 1.5em;
        }

        #logo, #icon {
            justify-self: center;
        }
    }
}

@media screen and (min-width: 1000px) {
    #nav-bar {
        height: 80px;
        grid-template-columns: 10% 20% 55% 5% 10%;
        background-color: #ffffff;

        #logo {
            width: 70px;
        }

        #icon {
            display: none;
        }

        #nav-drawer {
            display: none;
        }

        #header-title {
            grid-column: 2;
            font-size: 1.5em;
            justify-self: end;
            pointer-events: none;
        }

        #desktop-btn-container {
            grid-column: 3;
            display: grid;
            justify-items: center;
            align-items: center;
            grid-template-columns: 19% 1% 19% 1% 19% 1% 19% 1% 19%;
            width: 95%;
            justify-self: end;
            justify-items: center;

            .break-line {
                width: 1px;
                background-color: #d57f1e;
                height: 25px;
            }

            .extra-desk-btns {
                font-weight: bold;
                color: black;
                width: 100%;
                display: grid;
                justify-items: center;
                transition: all 0.4s ease-in-out;
                font-family: "Helvetica";
                font-size: 1em;
                text-decoration: none;

                &:hover {
                    color: #5565d9;
                }
            }
        }

        #shop-cart-component {
            justify-self: start;
        }

        #donate-btn {
            display: inline-block;
            justify-self: start;
            color: whitesmoke;
        }
    }
}

@media screen and (min-width: 1400px) {
    #nav-bar {

        #header-title {
            font-size: 1.7em;
        }

        #desktop-btn-container {
            .extra-desk-btns {
                font-size: 1.4em;
            }
        }
    }
}
</style>