<template>
    <div id="mission-statement">
        <div id="text-container">
            <h1>Our Mission</h1>
            <p>
                Calgary Animal Save aims to honor animals by advancing their social and political status. 
                Our organizational mission is to facilitate this in a manner that reflects our core values 
                of life-long learning, nonviolence, and building beloved community. 
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MissionStatement"
    }
</script>

<style lang="scss" scoped>
    #mission-statement {
        height: fit-content;
        padding-top: 30px;
        padding-bottom: 30px;
        display: grid;
        align-items: center;
        background: transparent;
        text-align: center;

        #text-container {
            height: fit-content;
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            display: grid;

            h1 {
                justify-self: center;
                color: #d57f1e;
                font-family: "Helvetica";
                font-size: 2.5em;
                margin-bottom: 2vh;
                background:
                    linear-gradient(#5565d9 0 0) 50% 100% /40% 2px;
                    background-repeat:no-repeat;
                    padding-bottom:6px;
            }
            
            p {
                margin-left: 10px;
                color: black;
                text-align: center;
                font-family:  'Roboto', sans-serif;
            }
        }
    }

@media screen and (min-width: 600px) {
    #mission-statement {

        #text-container {
            padding-left: 50px;
            padding-right: 50px;
            
            h1 {
                font-size: 3em;
            }
            
            p {
                font-size: 1.2em;
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    #mission-statement {

        #text-container {
            padding-left: 100px;
            padding-right: 100px;
            max-width: 1350px;

            h1 {
                font-size: 4em;
            }
            
            p {
                font-size: 1.4em;
                padding: 0 100px;
            }
        }
    }
}
</style>