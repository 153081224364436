<template>
  <v-app>
    <v-main>
      <AppearAnimate :delay="navbarDelay" :transitionIn="[0,'-70px']">
        <NavBar id="nav-bar" v-show="showNav" :showNav="showNav"/>
      </AppearAnimate>
      <div id="main-page">
          <router-view :key="$route.fullPath"/>
      </div>
      <Footer />
      <CookieAccept />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar.vue'
import AppearAnimate from './components/AppearAnimate.vue'
import Footer from './components/Footer.vue'
import CookieAccept from './components/CookieAccept.vue';
import { eventBus } from './main'

export default {
  name: 'App',
  components: { 
    NavBar,
    AppearAnimate,
    Footer,
    CookieAccept 
  },
  
  //everything below for scroll position is to handle the navbar scroll hide/show functionality.
  mounted() {
    window.addEventListener('scroll', this.updateScroll);

    window.addEventListener('scroll', () => {
      // detects new state and compares it with the old one
      if ((document.body.getBoundingClientRect()).top >= this.scrollPos) {
        this.showNav = true;
      }
      else
      {
        if ((document.body.getBoundingClientRect()).top <= -100) {
          this.showNav = false;
        }
      }
      // saves the new position for iteration.
      this.scrollPos = (document.body.getBoundingClientRect()).top;
    })
  },
  updated() {
    //shows nav bar when clicking checkout button to ensure mobile users that item was added to cart.
    eventBus.$on('showNavBar', () => {
      if (this.showNav == false) {
        this.showNav = true;
      }
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll); // To avoid memory leakage
  },
  data() {
    return {
        scrollPosition: 0,
        scrollPos: 0,
        showNav: true,
        navbarDelay: 2700,
    }
  },
  methods: {
    updateScroll() {
    this.scrollPosition = window.scrollY;
    this.navbarDelay = 10;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

    @font-face {
      font-family: "Helvetica";
      src: local("Helvetica"),
      url('./assets/fonts/HelveticaLTStd.otf') format("OpenType");
    }

    * {
        margin: 0;
        padding: 0;
        font-family:  'Roboto', sans-serif;
        background: white;
        overflow-x: hidden;
        position: relative;
    }
    
    #main-page {
      display: grid;
      justify-items: center;
      position: relative;
    }

    #main-page::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: url("~@/assets/main-page-back.jpg");
      background-position: center;
      background-attachment: fixed;
      background-size: cover;
      opacity: 0.4;
    }

    #nav-bar {
      position: fixed;
      z-index: 99;
    }

</style>
