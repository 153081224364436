<template>
    <div id="connect" @click="routeToConnect()">
        <h1>Connect with us</h1>
    </div>
</template>

<script>
import router from '../router'

    export default {
        name: "ConnectWithUsButton",
        methods: {
            routeToConnect() {
                router.push({name: 'Explore', hash: '#contact-us-container'});
            }
        }
    }
</script>

<style lang="scss" scoped>
    #connect {
        width: 90%;
        height: 300px;
        display: grid;
        justify-items: center;
        align-items: center;
        background-color: rgb(0, 0, 0);
        position: relative;
        cursor: pointer;
        text-align: center;
        margin-bottom: 20px;
        
        h1 {
            position: relative;
            font-size: 2.5em;
            color: white;
            border-radius: 5px;
            padding: 0 10px;
            font-family: "Helvetica";
        }

        &:hover {

            h1 {
            box-shadow: 
                0 0 25px 10px rgba(255, 255, 255, 0.24),
                inset 0 0 25px 10px rgba(255, 255, 255, 0.219);
            }
        }
    }

    #connect::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: url('~@/assets/interview.jpg');
        background-position: center;
        background-size: cover;
        opacity: 0.5;
    }

    #connect:hover:before {
        opacity: 0.3;
    }

@media screen and (min-width: 600px) {
    #connect {
        width: 70%;
        justify-self: center;
        margin-bottom: 25px;

        h1 {
            font-size: 3.5em;
        }
    }
}

@media screen and (min-width: 1000px) {
    #connect {
        max-width: 900px;
        height: 400px;
        justify-self: center;

        h1 {
            font-size: 4em;
        }
    }
}
</style>