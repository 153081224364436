<template>
    <transition :css="false" @before-enter="beforeEnter" @enter="enter">
        <slot />
    </transition>
</template>

<script>
    export default {
        name: "AppearAnimate",
        props: {
            duration: {
                type: Number,
                default: 500
            },
            delay: {
                type: Number,
                default: 0
            },
            transitionIn: Array,
            transitionOut: Array,
        },
        methods: {
            beforeEnter(el) {
                el.style.opacity = 0;

                if(this.transitionIn) {
                    el.style.transform = "translate("+this.transitionIn.join(',')+")";
                }
            },
            enter(el) {
                let transitions = "opacity "+this.duration+"ms ease-in-out "+this.delay+"ms";

                if(this.transitionIn) {
                    transitions += ", transform "+this.duration+"ms ease-in-out "+this.delay+"ms";
                }

                el.style.transition = transitions;

                getComputedStyle(el);

                setTimeout(() => {
                    el.style.opacity = 1;

                    if(this.transitionIn) {
                        el.style.transform = "initial";
                    }
                });
            },         
        }
    }
</script>