<template>
    <div id="hero-section">
        <v-btn id="actions-btn"
            @click="$router.push('Action')"
            >
            Take Action for Animals
        </v-btn>
        <div id="carousel">
            <ImageCarousel />
        </div>
    </div>
</template>

<script>
import ImageCarousel from './ImageCarousel.vue'

    export default {
        name: "HeroSection",
        components: {
            ImageCarousel
        }
    }
</script>

<style lang="scss" scoped>
    #hero-section {
        width: 100%;
        height: 250px;
        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: 100%;
        box-shadow: 0 4px 20px -5px #000000;

        #carousel {
            grid-area: 1 / 1;
            height: 100%;
            width: 100%;
        }

        #actions-btn {
            max-width: 100%;
            grid-area: 1 / 1;
            z-index: 10;
            padding: 7% 10% 7% 10%;
            justify-self: center;
            align-self: center;
            font-size: 1em;
            background-color: #d5801ecc;
            color: whitesmoke;
            transition: all 0.3s ease-in-out;

            &:hover {
                transform: scale(1.1);
                background-color: #d57f1e;
            }
        }
    }

@media screen and (min-width: 600px) {
    #hero-section {
        height: 350px;

        #actions-btn {
            padding: 5% 10%;
            font-size: 1.4em;
        }
    }
}

@media screen and (min-width: 1000px) {
    #hero-section {
        height: 450px;

        #actions-btn {
            padding: 3% 8%;
            font-size: 1.7em;
        }
    }
}
</style>