<template>
    <v-lazy
        v-model="lazyLinksActive"
        :options="{
        threshold: 0.1
        }"
        min-height="200"
        transition="scale-transition"
        >
        <v-card 
            id="action-card"
            class="mx-auto"
            elevation="12"
            color="#e89f4c00"
            v-show="cardLoaded"
        >
            <v-img
                id="card-image"
                :src="campaign.image_url"
                class="white--text align-center"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                @click.self="goToAction(campaign.title)"
                eager
                @load="imgToLoad"
            >
                <v-card-title id="title" v-text="campaign.title" style="word-break: break-word"></v-card-title>
                <v-btn
                    v-if="!hideChevron"
                    id="subtitle-chevron"
                    icon
                    color="white"
                    @click="show = !show;"
                    
                >
                    <v-icon>{{ show ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }}</v-icon>
                </v-btn>
            </v-img>
            <v-expand-transition>
            <div v-show="show" id="card-dropdown">
                <v-divider></v-divider>

                <v-card-text v-html="this.campaign.subtitle"></v-card-text>
                <v-btn @click="goToAction(campaign.title)" id="expand-btn">Learn More</v-btn>
            </div>
            </v-expand-transition>
        </v-card>
    </v-lazy>
</template>

<script>
import router from '../router'

    export default {
        name: "ActionCard",
        props: {
            campaign: [Object, Array],
            hideChevron: [Boolean],
        },
        data() {
            return {
                show: false,
                lazyLinksActive: false,
                cardLoaded: false,
            }
        },
        methods: {
            goToAction(title){
                title = title.replace(/ /g, "-" );
                title = title.toLowerCase();
                router.push('/campaign/'+title);
            },
            imgToLoad() {
                this.cardLoaded = true;
            }
        }
    }
</script>

<style lang="scss" scoped>

    #action-card {
        width: 90vw;
        min-height: 300px;
        transition: all 0.2s ease-in-out;

        #card-image {
            width: 100%;
            height: 300px;
            object-fit: cover;
            cursor: pointer;

            #title {
                justify-content: center; //align is done up in html templace due to v-card rules
                font-family: "Helvetica";
                font-weight: bold;
                font-size: 2em;
            }

            #subtitle-chevron {
                position: absolute;
                bottom: 5px;
                right: 20px;
            }
        }

        #card-image::before {
            content: "";
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background-color: black;
            opacity: 0;
        }

        #card-image:hover:before {
            opacity: 0.2;
        }

        #expand-btn {
            background-color: #e89f4c;
            margin-bottom: 10px;
            margin-left: 40%;
        }

        #card-dropdown {
            background-color: #ffffff;
        }
    }

    #action-card:hover {
        transform: scale(1.02);
    }

@media screen and (min-width: 1000px) {
    #action-card {
        width: 95%;
        max-width: 1300px;

        #expand-btn {
            margin-left: 60%;
        }
    }
}
</style>