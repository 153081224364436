<template>
    <div id="feature-container">
        <v-dialog
            v-model="dialog"
            width="800"
            transition="dialog-bottom-transition"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                v-if="ulog"
                id="feature-edit-btn"
                color="error"
                dark
                v-bind="attrs"
                v-on="on"
                >
                Change feature
                </v-btn>
            </template>
            <v-card id="feature-dialog-card">
                <v-form id="feature-form" v-model="formValid">
                    <v-text-field
                        v-model="title"
                        :rules="[titleRules.required, titleRules.min, titleRules.max]"
                        color="primary"
                        required
                        >
                        <template v-slot:label>
                            Title<span class="red--text"> *</span>
                        </template>
                    </v-text-field>
                    <div id="featured-error-message">
                        <h2>{{errorMsg}}</h2>
                    </div>
                    <div id="form-btns">
                        <v-btn
                            id="update-btn"
                            class="white--text"
                            color="primary"
                            @click="updateFeatured"
                        >
                            Update
                        </v-btn>
                    </div>
                </v-form>
            </v-card>
        </v-dialog>
        <div id="feature-title">
            <ExplorePageTitles title="Campaign Spotlight"/>
        </div>
        <div id="card-container">
            <ActionCard v-if="feature != null" :campaign="feature" :hideChevron="true"/>
        </div>
        <div id="action-info">
            <h3 v-if="feature != null" v-html="featureSubToHtml"></h3>
        </div>
        <div id="feature-btns">
            <v-btn
                v-if="feature != null" 
                @click="goToAction(feature.title)" 
                id="expand-feature-btn"
                color="primary"
                >
                Learn More
            </v-btn>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import ActionCard from './ActionCard.vue'
import router from '../router'
import cookies from 'vue-cookies'
import ExplorePageTitles from './ExplorePageTitles.vue'
import { marked } from 'marked'

    export default {
        name: "FeatureCampaign",
        components: {
            ActionCard,
            ExplorePageTitles,
        },
        computed: {
            sessionCookie() {
                return cookies.get('_casLt'); 
            },
            featureSubToHtml() {
                if (this.feature.subtitle != null) {
                    return marked(this.feature.subtitle);
                }
                else {
                    return null;
                }
            }
        },
        beforeMount() {
            this.getFeature();
        },
        mounted() {
            if (this.sessionCookie != undefined){
                this.ulog = true;
            }
        },
        data() {
            return {
                ulog: false,
                feature: null,
                formValid: false,
                dialog: null,
                title: '',
                titleRules: {
                    required: v => !!v || 'Title is required',
                    min: v => v.length >= 1 || 'Min 1 character',
                    max: v => v.length <= 200 || 'Max 200 characters',
                },
                errorMsg: '',
            }
        },
        methods: {
            getFeature() {
                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/feature_action',
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.feature = response.data 
                }).catch(() => {
                    //do nothing
                });
            },
            goToAction(title){
                router.push('/campaign/'+title);
            },
            updateFeatured() {
                let token = cookies.get('_casLt');

                axios.request({
                    url: process.env.VUE_APP_API_SITE+'/api/feature_action',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        "loginToken": token,
                        "title": this.title
                    }
                }).then(() => {
                    router.go();
                }).catch((error) => {
                    this.errorMsg = error.response.data;
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    #feature-container {
        width: 100%;
        padding: 20px 20px 40px 20px;
        margin: 20px 0;
        background-color: #f8f8f8;
        display: grid;
        grid-template-rows: auto auto;
        font-family: "Helvetica";
        text-align: center;

        #feature-edit-btn {
            width: fit-content;
            justify-self: end;
        }

        #feature-title{
            margin: 0 0 30px 0;
        }

        h3 {
            margin: 40px 0 30px 0;
        }

        #card-container {
            max-width: 700px;
            justify-self: center;
        }
    }

    #feature-dialog-card {
        padding: 20px;

        #featured-error-message {
            color: red;
        }
    }

@media screen and (min-width: 1000px) {
    #feature-container {

        #expand-feature-btn {
            display: none;
        }

        h3 {
            padding: 0 150px;
        }
    }
}
</style>