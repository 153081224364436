<template>
    <div id="cart-item">
        <img :src="item.imageUrl" alt='item' />
        <div id='cart-item-details'>
            <span className='name'>{{item.name}}</span>
            <span className='price'>{{item.quantity}} x ${{item.price}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CartItem",
        props: ["item"],
    }
</script>

<style lang="scss" scoped>
    #cart-item {
    width: 100%;
    display: flex;
    height: 80px;
    margin-bottom: 15px;

    img {
        width: 30%;
        object-fit: cover;
    }

    #cart-item-details {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 10px 20px;

        .name {
        font-size: 16px;
        }
    }
}
</style>