<template>
    <div id="cookie-accept">
        <p>This website uses cookies to ensure you get the best experience on our website. <a href="/privacy-policy">Learn More</a></p>
        <v-btn
            id="cookie-accept-btn"
            class="accept-decline-cookie-btns"
            outlined
            @click="acceptCookies()"
            >
            Accept
        </v-btn>
        <v-btn
            id="cookie-decline-btn"
            class="accept-decline-cookie-btns"
            outlined
            @click="declineCookies()"
            >
            Decline
        </v-btn>
    </div>
</template>

<script>
    export default {
        name: "CookieAccept",
        mounted() {
            if (!localStorage.getItem("casCookieBannerClicked")) {
                setTimeout(() => {
                    document.getElementById("cookie-accept").classList.add("active-accept");
                }, 1500)
            }
        },
        methods: {
            declineCookies() {
                localStorage.setItem("casCookieBannerClicked", "true");
                localStorage.setItem("casIsCookieAccept", "false");
                document.getElementById("cookie-accept").classList.remove("active-accept");
            },
            acceptCookies() {
                localStorage.setItem("casCookieBannerClicked", "true");
                localStorage.setItem("casIsCookieAccept", "true");
                document.getElementById("cookie-accept").classList.remove("active-accept");
            }
        }
    }
</script>

<style lang="scss" scoped>
    #cookie-accept {
        width: 100vw;
        padding: 0 30px;
        background-color: #1d1622;
        color: white;
        position: fixed;
        bottom: -100%;
        display: grid;
        align-items: center;
        box-shadow: 0 -2px 16px #1d162283;
        transition: 1.5s all ease-in-out;

        .accept-decline-cookie-btns {
            height: 50px;
            margin: 10px;
            color: white;
        }

        p {
            margin: 15px 0;

            a {
                color: white;
            }
        }
    }

    #cookie-accept.active-accept {
        bottom: 0;
    }

@media screen and (min-width: 600px) {
        #cookie-accept {
        height: 10vh;
        grid-template-columns: 60% 20% 20%;

        p {
            margin: 0;
        }
    }
}

@media screen and (min-width: 1000px) {
        #cookie-accept {
        grid-template-columns: 60% 15% 15% 10%;

        .accept-decline-cookie-btns {
            height: 50px;
            margin: 10px;
            width: fit-content;
            padding: 0 30px;
        }
    }
}

@media screen and (min-width: 1500px) {
        #cookie-accept {

        .accept-decline-cookie-btns {
            padding: 0 70px;
        }
        
        p {
            margin-left: 5vw;
            font-size: 1.1em;
        }
    }
}
</style>