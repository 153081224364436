<template>
    <div id="container">
        <h1>{{title}}</h1>
    </div>
</template>

<script>
    export default {
        name: "ExplorePageTitles",
        props: ["title"]
    }
</script>

<style lang="scss" scoped>
    #container {
        display: grid;
        justify-items: center;
        align-items: center;
        width: 100%;
        height: fit-content;
    }
    h1 {
        color: #d57f1e;
        font-family: "Helvetica";
        font-size: 2.5em;
        text-align: center;
        margin-bottom: 2vh;
        background:
            linear-gradient(#5565d9 0 0) 50% 100% /40% 2px;
            background-repeat:no-repeat;
            padding-bottom:6px;
    }

@media screen and (min-width: 600px) {

    #container {
        padding-left: 50px;
        padding-right: 50px;
        
    }
}

@media screen and (min-width: 1000px) {


    #container {
        padding-left: 100px;
        padding-right: 100px;

        h1 {
            font-size: 3em;
        }
    }
}

</style>