var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"home-page"}},[_c('HeroSection'),_c('div',{attrs:{"id":"get-campaign-members"}},[_c('h1',{attrs:{"id":"member-form-title"}},[_vm._v("Get updates from Calgary Animal Save")]),_c('p',{attrs:{"id":"member-form-subtitle"}},[_vm._v("Join our email list for all the latest campaign updates and action alerts.")]),_c('div',{attrs:{"id":"camp-form-container"}},[_c('CampaignForm')],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"id":"site-logo","src":require("@/assets/logo.png"),"alt":"Site logo"},on:{"click":function($event){return _vm.$router.push('/explore')}}},'v-img',attrs,false),on))]}}])},[_c('span',[_vm._v("Visit our explore page")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"id":"asm-logo","src":require("@/assets/asmLogo.png"),"alt":"Animal Save Movement logo"},on:{"click":_vm.goToAnimalMovementSite}},'v-img',attrs,false),on))]}}])},[_c('span',[_vm._v("Visit thesavemovement.org")])])],1),_c('div',{attrs:{"id":"mission-container"}},[_c('MissionStatement',{attrs:{"id":"mission-statement"}})],1),_c('v-lazy',{attrs:{"options":{
      threshold: 0.1
      },"min-height":"200"},model:{value:(_vm.lazyLinksDonate),callback:function ($$v) {_vm.lazyLinksDonate=$$v},expression:"lazyLinksDonate"}},[_c('div',{attrs:{"id":"donate-home-section-test"}},[_c('a',{attrs:{"href":"/donate"}},[_c('img',{attrs:{"id":"donate-vector-img","src":require("@/assets/donate-hands.png"),"alt":"Accepting donation vector"}})])])]),_c('div',{attrs:{"id":"feature-campaign"}},[_c('FeatureCampaign')],1),_c('div',{attrs:{"id":"vol-ops-request"}},[_c('img',{attrs:{"id":"leo-quote-animal","src":require("@/assets/LeoQuote.jpg"),"alt":"Leo Tolstoy quote"}}),_c('div',{attrs:{"id":"vol-ops-text"}},[_c('h3',[_vm._v("Calgary Animal Save is urgently seeking:")]),_vm._m(0),_c('v-btn',{attrs:{"id":"vol-ops-contact-details","color":"#d57f1e","href":"/explore#volunteer-form"}},[_vm._v(" Contact us for details! ")])],1)]),_c('div',{attrs:{"id":"home-connect-btn"}},[_c('ConnectWithUsButton')],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[_c('li',[_vm._v("A social media coordinator (estimated 5 hours a week)")]),_c('li',[_vm._v("A vigil coordinator (estimated 2 hours a week)")])])}]

export { render, staticRenderFns }