import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

//import components this way to speed up app by delivering only components required per page load
const Action = () => 
  import(/* webpackChunkName: "Action" */'../views/Action.vue')
const Campaign = () => 
  import(/* webpackChunkName: "Campaign" */'../views/Campaign.vue')
const Explore = () => 
  import(/* webpackChunkName: "Explore" */'../views/Explore.vue')
const Ulog = () => 
  import(/* webpackChunkName: "Ulog" */'../views/Ulog.vue')
const Privacy = () => 
  import(/* webpackChunkName: "Privacy" */'../views/Privacy.vue')
const Donate = () => 
  import(/* webpackChunkName: "Donate" */'../views/Donate.vue')
const Shop = () => 
  import(/* webpackChunkName: "Shop" */'../views/Shop.vue')
const Products = () => 
  import(/* webpackChunkName: "Products" */'../views/Products.vue')
const Checkout = () => 
  import(/* webpackChunkName: "Checkout" */'../views/Checkout.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/action',
    name: 'Action',
    component: Action,
  },
  {
    path: '/campaign/:title',
    name: 'campaign',
    component: Campaign,
  },
  {
    path: '/explore',
    name: 'Explore',
    component: Explore,
  },
  {
    path: '/ulog',
    name: 'Ulog',
    component: Ulog,
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/donate',
    name: 'Donate',
    component: Donate,
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop,
  },
  {
    path: '/shop/products/:id',
    name: 'Products',
    component: Products,
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
  },
  {
    path: '/*',
    redirect: '/',
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base: "/",
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    let position = {}
    if (to.hash) {
        position = {
          selector: to.hash,
          offset: { x: 0, y: 100 },
          behavior: 'smooth',
        }
    } else {
        position = { x: 0, y: 0 }
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(position)
      }, 400)
    })
  }
})

export default router
